<template>
  <div class="page-wrapper">
    <!-- 活动时间 -->
    <div class="time-box">
      <!-- 补贴活动 -->
      <div class="title">{{activity.title}}</div>
      <div>
        {{ timeList[0] + "--" + timeList[1] }}
      </div>
    </div>
    <!-- 活动内容 -->
    <div class="active-content">
      <!-- 活动内容 -->
      <div class="title">{{activity.title}}</div>
      <div class="active-txt" v-html="activity.activeContent"/>
    </div>
    <!-- 补贴活动一 -->
    <div class="subsidy-box">
      <!-- 补贴活动一： -->
      <div class="title">{{activity.activeInfo1.title}}</div>
      <div class="active-txt">
       {{activity.activeInfo1.content}}
      </div>
      <div class="active-plan">
        <!-- 社区成员： -->
        <span class="active-title">{{activity.member}}：</span>
        <span class="color-1">{{ info.communityMemberNumber || 0 }}</span
        >/{{memberNum}}
        <span class="has-active">（{{active1 ? activity.Conforming : activity.NonConforming }}）</span>
      </div>
      <!-- 领取补贴 -->
      <van-button class="receive-btn" color="#A85FFC"
      :disabled="!active1 || info.isCommunityReceive !== 0 || info.communityRemaining <= 0 "
      :loading="btnLoading0" @click="handleRecieve(0)">
      {{activity.receiveSubsidy}}
      </van-button>
      <div class="tips">
        <!-- 剩余补贴： -->
        <span>{{activity.remainder}}：</span>
        <span class="color-2">{{ info.communityRemaining || 0}}</span>
        <span>{{activity.portion}}</span>
      </div>
    </div>
    <!-- 补贴活动二 -->
    <div class="subsidy-box">
       <!-- 补贴活动二： -->
      <div class="title">{{activity.activeInfo2.title}}</div>
      <div class="active-txt">
       {{activity.activeInfo2.content}}
      </div>
        <div class="active-plan">
        <!-- 社区成员： -->
        <span class="active-title">{{activity.member}}：</span>
        <span class="color-1">{{ info.communityMemberNumber || 0 }}</span
        >/{{memberNum}}
        <span class="has-active">（{{active1 ? activity.Conforming : activity.NonConforming }}）</span>
      </div>
       <div class="active-plan">
         <!-- 新增质押： -->
        <span class="active-title">{{activity.AddedPledge}}：</span>
        <span class="color-1">{{ info.mebAmount || 0}}</span
        >/{{stakeNum}}
        <span class="has-active">（{{active2 ? activity.Conforming : activity.NonConforming}}）</span>
      </div>
      <van-button class="receive-btn" color="#A85FFC"
      :disabled="!active1 || !active2 || info.isLpReceive !== 0 || info.lpRemaining <= 0"
      :loading="btnLoading1"
       @click="handleRecieve(1)"> {{activity.receiveSubsidy}}</van-button>
      <div class="tips">
         <!-- 剩余补贴： -->
        <span>{{activity.remainder}}：</span>
        <span class="color-2">{{ info.lpRemaining || 0 }}</span>
         <span>{{activity.portion}}</span>
        </div>
    </div>
  </div>
</template>

<script>
import { queryActiveInfo, queryActiveReceive } from '../../services/community'
export default {
  name: 'SubsidizedActivities',
  data () {
    return {
      timeList: ['2022/04/08', '2022/05/08'],
      info: {
        communityMemberNumber: 0, // 社区成员
        communityRemaining: 0, // 补贴1剩余份数
        isCommunityReceive: 0, // 补贴1是否领取
        mebAmount: 0, // 新增质押数
        lpRemaining: 0, // 补贴二剩余份数
        isLpReceive: 0 // 补贴二是否领取
      },
      memberNum: 100,
      stakeNum: 100000,
      active1: false, // 社区成员达标标识
      active2: false, // 质押达标标识
      btnLoading0: false,
      btnLoading1: false
    }
  },
  computed: {
    activity () {
      return this.$t('community.activity')
    }
  },
  methods: {
    init () {
      this.getActiveInfo()
    },
    async getActiveInfo () {
      const resp = await queryActiveInfo()
      if (!resp.success) return
      this.info = resp.result || this.info
      // this.info.communityMemberNumber = 100
      // this.info.mebAmount = '1000001'
      this.active1 = parseFloat(this.info.communityMemberNumber) >= this.memberNum
      this.active2 = parseFloat(this.info.mebAmount) >= this.stakeNum
    },
    // 领取奖励 0社区 1 质押
    async handleRecieve (type) {
      this.$data[`btnLoading${type}`] = true
      const resp = await queryActiveReceive(type)
      this.$data[`btnLoading${type}`] = false
      if (!resp.success) return false
      this.$toast('success')
      setTimeout(() => {
        this.init()
      }, 1500)
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style scoped lang="scss">
.page-wrapper {
  background: linear-gradient(-45deg, #2c68ee, #020245);
  padding: 0 13px 20px 13px;
  box-sizing: border-box;
  .title {
    font-size: 15px;
    font-weight: bold;
    color: #fff;
  }
  .time-box {
    padding: 20px 0px;
    box-sizing: border-box;
    background: linear-gradient(to bottom, #8b3ce8, #2f0067);
    border-radius: 0 0 10px 10px;
    border: 2px solid #a85fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: #ee8b23;
    margin-bottom: 10px;
    .title {
      color: #fff;
      margin-bottom: 10px;
      font-size: 15px;
    }
  }
  .active-content {
    padding: 10px 40px;
    box-sizing: border-box;
    text-align: center;
    .active-txt {
      margin-top: 10px;
      color: #f1e5ff;
      line-height: 1.5em;
      font-size: 12px;
    }
  }
  .subsidy-box {
    width: 100%;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    box-sizing: border-box;
    padding: 20px;
    margin: 10px 0;
    font-size: 12px;
    .title {
      color: #562391;
    }
    .active-txt {
      color: #2a2b33;
      margin: 10px 0;
    }
    .active-plan {
      font-weight: bold;
      color: #030007;
      margin-bottom: 10px;
      font-weight: bold;
    }
    .receive-btn {
      width: 100%;
      height: 50px;
      border-radius: 10px;
      margin: 10px 0;
      font-weight: bold;
      font-size: 17px;
    }
    .tips {
      text-align: center;
      color: #562391;
      font-weight: bold;
    }
    .color-1 {
      color: #a85fff;
    }
    .color-2 {
      color: #ee8b23;
    }
    .color-3 {
      color: #ed8080;
    }
  }
}
</style>
